import BaseService from './BaseService';

const resource = 'api/v3/';

class AuthenticationService extends BaseService {
  constructor() {
    super({ resource });
  }

  async login(form) {
    const action = 'token/';
    return this.http.post(this.resource + action, form);
  }

  async register(form) {
    const action = 'users/register/';
    return this.http.post(this.resource + action, form);
  }

  async userInfo() {
    const action = 'token/user-info/';
    return this.http.get(this.resource + action);
  }

  async getDataFromPeopleService(idNumber) {
    const action = `citizens/${idNumber}/details/`;
    return this.http.get(this.resource + action);
  }

  async resetPassword(form) {
    const action = 'token/rest-auth/password/reset/';
    return this.http.post(this.resource + action, form);
  }

  async recoverPassword(form) {
    const action = 'token/rest-auth/password/reset/confirm/';
    return this.http.post(this.resource + action, form);
  }
}

export default AuthenticationService;
