<style scoped lang="sass">
  @use '../../../../../styles/variables'
</style>

<template>
  <v-container>
    <router-link :to="{ path: '/request/'+$route.params.id+'/contractors'}">
      <v-btn class="use-main-color-outline my-4" variant="outlined">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('Volver atrás') }}
      </v-btn>
    </router-link>

    <register-contractor-form></register-contractor-form>
  </v-container>
</template>

<script setup>
import RegisterContractorForm from '@/components/forms/requests/RegisterContractorForm.vue';

definePage({
  name: 'RequestContractorsAddPage',
  meta: {
    requiresAuth: true,
    isAuthPage: false,
  },
});

</script>
