import BaseService from './BaseService';

const resource = 'api/v3/sap/?url=/api_scc_cc/';

class ContractorService extends BaseService {
  constructor() {
    super({ resource });
  }

  async get_contractor_info() {
    const action = 'contractor/get-data/';
    const response = await this.http.get(this.resource + action);
    return response.data;
  }
}

export default ContractorService;
