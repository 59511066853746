import BaseService from './BaseService';

const resource = 'api/v3/sap/?url=/api_scc_cc/';

class AppService extends BaseService {
  constructor() {
    super({ resource });
  }

  async getFileConfig() {
    const action = 'document-required/';
    return this.http.get(this.resource + action);
  }

  async getContractorMaterial(id) {
    const action = `request-contractor-type/${id}/`;
    return this.http.get(this.resource + action);
  }
}

export default AppService;
