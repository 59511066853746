<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted } from 'vue';
import types from '@/types';
import useAuthStore from '@/stores/auth.store';
import { useAppStore } from '@/stores/app';

const authStore = useAuthStore();
const appStore = useAppStore();

onMounted(async () => {
  await appStore[types.SETUP_APP]();
  if (authStore[types.IS_LOGGED_IN]()) {
    try {
      await authStore[types.GET_USER]();
    } catch (error) {
      authStore[types.LOGOUT]();
    }
  }
});
</script>
