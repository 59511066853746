export default {
  // App
  SETUP_APP: 'SETUP_APP',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  GET_NATIONALITIES: 'GET_NATIONALITIES',
  GET_CONTRACTOR_MATERIAL: 'GET_CONTRACTOR_MATERIAL',

  // User
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  REGISTER: 'REGISTER',
  LOGOUT: 'LOGOUT',
  GET_USER: 'GET_USER',
  SET_TOKEN: 'SET_TOKEN',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  GET_DATA_FROM_PEOPLE_SERVICE: 'GET_DATA_FROM_PEOPLE_SERVICE',
  GET_DATA_BY_IDENTIFICATION: 'GET_DATA_BY_IDENTIFICATION',

  // Requests
  GET_REQUESTS: 'GET_REQUESTS',
  GET_REQUEST_TYPES: 'GET_REQUEST_TYPES',
  GET_REQUEST_STATUSES: 'GET_REQUEST_STATUSES',
  GET_REQUEST_IDENTIFICATION_TYPES: 'GET_REQUEST_IDENTIFICATION_TYPES',
  CREATE_REQUEST: 'CREATE_REQUEST',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  SET_DOCUMENT_REQUEST: 'SET_DOCUMENT_REQUEST',
  GET_REQUEST_DOCUMENTS: 'GET_REQUEST_DOCUMENTS',
  SET_DOCUMENT_CARNET_REQUEST: 'SET_DOCUMENT_CARNET_REQUEST',
  GET_REQUEST_CARNET_DOCUMENTS: 'GET_REQUEST_CARNET_DOCUMENTS',
  REQUEST_ADD_CONTRACTOR: 'REQUEST_ADD_CONTRACTOR',
  REQUEST_UPDATE_CONTRACTOR: 'REQUEST_UPDATE_CONTRACTOR',
  REQUEST_VALIDITIES: 'REQUEST_VALIDITIES',
  CREATE_REQUEST_HEAD: 'CREATE_REQUEST_HEAD',
  GET_REQUEST_HEAD: 'GET_REQUEST_HEAD',
  GET_REQUESTS_FROM_HEAD: 'GET_REQUESTS_FROM_HEAD',
  GET_FILE_CONFIG: 'GET_FILE_CONFIG',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  GET_CARNETS_TEMPORARY: 'GET_CARNETS_TEMPORARY',
  GET_INVOICE: 'GET_INVOICE',
  GET_ACCESS_AREAS: 'GET_ACCESS_AREAS',

  // Contractor
  GET_CONTRACTOR: 'GET_CONTRACTOR',

  // Payment
  CREATE_PAYMENT_ATTEMPT: 'CREATE_PAYMENT_ATTEMPT',
  PROCESS_PAYMENT: 'PROCESS_PAYMENT',
  PROCESS_PAYMENT_SUBSCRIPTION: 'PROCESS_PAYMENT_SUBSCRIPTION',
};
