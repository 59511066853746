<style scoped lang="sass">
@use '../../../../styles/variables.scss'
</style>
<template>
  <v-container class="my-3">
    <v-btn
      :prepend-icon="'mdi-arrow-left'"
      class="mr-4 mb-4 use-main-color-outline"
      @click="backToHome()"
      variant="outlined">{{ $t('Volver al Inicio') }}</v-btn>
    <RegisterRequestForm/>
  </v-container>
</template>

<script setup>
import { useRouter } from 'vue-router';
import RegisterRequestForm from '@/components/forms/requests/RegisterRequestForm.vue';

const router = useRouter();

definePage({
  name: 'RequestRegisterPage',
  alias: ['/request/register/:id?'],
  meta: {
    requiresAuth: true,
    isAuthPage: false,
  },
});

const backToHome = () => {
  router.push({ path: '/' });
};
</script>
