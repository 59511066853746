<template>
  <v-card max-width="400" class="elevation-3 px-5 mx-sm-auto mx-6" style="position: relative; top: 40%; transform: translate(0, -50%)">
    <v-card-title class="text-center">{{$t("Iniciar Sesión")}}</v-card-title>
    <v-card-text v-if="$route.query.registered" class="px-2 py-1 use-main-color text-center" style="border-radius: 5px">
      {{ $t("El usuario se ha registrado, puede inciar sesión.") }}
    </v-card-text>
    <v-card-text v-if="$route.query.reset" class="px-2 py-1 use-main-color text-center" style="border-radius: 5px">
      {{ $t("La contraseña ha sido cambiada correctamente, puede iniciar sesión.") }}
    </v-card-text>
    <login-form/>
  </v-card>
</template>

<style scoped lang="sass">

</style>

<script setup>
import LoginForm from '@/components/forms/auth/LoginForm.vue';

definePage({
  name: 'LoginPage',
  meta: {
    requiresAuth: false,
    isAuthPage: true,
  },
});

</script>
