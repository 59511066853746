<style scoped lang="sass">

</style>
<template>
    <v-card
      max-width="400"
      class="elevation-3 px-5 mx-sm-auto mx-6"
      style="position: relative; top: 50%; transform: translate(0, -50%)">
      <v-card-title class="text-center">{{$t("Registro de Usuario")}}</v-card-title>
      <RegisterForm/>
    </v-card>
</template>

<script setup>

import RegisterForm from '@/components/forms/auth/RegisterForm.vue';

definePage({
  name: 'RegisterPage',
  meta: {
    requiresAuth: false,
    isAuthPage: true,
  },
});
</script>
