// Plugins
import { registerPlugins } from '@/plugins';
import { createI18n } from 'vue-i18n';
import es from '@/locales/es.json';
import en from '@/locales/en.json';
import VueTheMask from 'vue-the-mask';
import * as Sentry from '@sentry/vue';
// Components
import { createApp } from 'vue';
import App from './App.vue';

// Composables

const app = createApp(App);

registerPlugins(app);

const i18n = createI18n({
  legacy: false,
  locale: 'Español',
  missingWarn: false,
  fallbackWarn: false,
  silentTranslationWarn: true,
  messages: {
    Español: es,
    English: en,
  },
});

app.config.globalProperties.$filters = {
  toCurrency(value, currency = 'USD') {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
    if (currency === 'USD') {
      return currency + formatter.format(value);
    }
    return formatter.format(value);
  },
};

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
    environment: import.meta.env.VITE_ENVIRONMENT,
  });
}

app.use(i18n);
app.use(VueTheMask);
app.mount('#app');
