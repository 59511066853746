<template>
  <request-details
    :request="requestHeadById"
    :request_id="route.params.id"
    :contractorsSelected="contractorsSelected"
    :contractorsSelectedAll="contractorsSelectedAll"
    :showModalPaymentRequest="showModalPaymentRequest"
    :loadRequestsHead="loadRequestsHead"
  />

  <contractor-datatable
    :request_head="requestHeadById"
    :requests="requestHeadById?.request_cards"
    :contractorsSelected="contractorsSelected"
    :contractorsSelectedAll="contractorsSelectedAll"
  />

</template>

<script setup>
import {
  ref,
  onMounted,
} from 'vue';
import RequestDetails from '@/components/contractors/RequestDetails.vue';
import ContractorDatatable from '@/components/contractors/ContractorDatatable.vue';

import useRequestStore from '@/stores/request.store';
import { useRoute, useRouter } from 'vue-router';
import types from '@/types';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/stores/app';

const route = useRoute();
const router = useRouter();
const requestStore = useRequestStore();
const appStore = useAppStore();
const { requestHeadById } = storeToRefs(requestStore);
const contractorsSelected = ref({ value: [] });
const contractorsSelectedAll = ref([]);
const showModalPaymentRequest = ref({ value: false });

definePage({
  name: 'RequestContractorsPage',
  meta: {
    requiresAuth: true,
    isAuthPage: false,

  },
});

const loadRequestsHead = async () => {
  await requestStore[types.GET_REQUEST_HEAD]({ id: route.params.id }).catch((error) => {
    if (error.response.status === 400) {
      router.push({ name: 'Home' });
    }
  });
};

onMounted(async () => {
  await appStore[types.SETUP_APP]();
  await loadRequestsHead().then(() => {
    requestHeadById?.value?.request_cards.forEach((card) => {
      contractorsSelectedAll.value.push(card);
    });
  });
});
</script>
