<style scoped lang="sass">

</style>

<template>
  <recover-password-form/>
</template>

<script setup>

import RecoverPasswordForm from '@/components/forms/auth/RecoverPasswordForm.vue';

definePage({
  name: 'RecoverPasswordPage',
  meta: {
    requiresAuth: false,
    isAuthPage: true,
  },
});
</script>
