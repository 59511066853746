import _definePage_default_0 from '/tmp/build_a2694fbe/src/pages/auth/forgot-password/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/tmp/build_a2694fbe/src/pages/auth/login/index.vue?definePage&vue'
import _definePage_default_3 from '/tmp/build_a2694fbe/src/pages/auth/register/index.vue?definePage&vue'
import _definePage_default_4 from '/tmp/build_a2694fbe/src/pages/home/index.vue?definePage&vue'
import _definePage_default_5 from '/tmp/build_a2694fbe/src/pages/request/[id]/checkout/index.vue?definePage&vue'
import _definePage_default_6 from '/tmp/build_a2694fbe/src/pages/request/[id]/contractors/index.vue?definePage&vue'
import _definePage_default_7 from '/tmp/build_a2694fbe/src/pages/request/[id]/contractors/add/index.vue?definePage&vue'
import _definePage_default_8 from '/tmp/build_a2694fbe/src/pages/request/[id]/contractors/add/[contractor_id].vue?definePage&vue'
import _definePage_default_9 from '/tmp/build_a2694fbe/src/pages/request/register/[[id]]/index.vue?definePage&vue'
import _definePage_default_10 from '/tmp/build_a2694fbe/src/pages/user/[uid]/[token]/reset-password/index.vue?definePage&vue'

export const routes = [
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
      {
        path: 'forgot-password',
        /* internal name: '/auth/forgot-password' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'ForgotPasswordPage',
            component: () => import('/tmp/build_a2694fbe/src/pages/auth/forgot-password/index.vue'),
            /* no children */
          },
  _definePage_default_0
  )
        ],
      },
      {
        path: 'login',
        /* internal name: '/auth/login' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'LoginPage',
            component: () => import('/tmp/build_a2694fbe/src/pages/auth/login/index.vue'),
            /* no children */
          },
  _definePage_default_2
  )
        ],
      },
      {
        path: 'register',
        /* internal name: '/auth/register' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'RegisterPage',
            component: () => import('/tmp/build_a2694fbe/src/pages/auth/register/index.vue'),
            /* no children */
          },
  _definePage_default_3
  )
        ],
      }
    ],
  },
  {
    path: '/home',
    /* internal name: '/home' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Home',
        component: () => import('/tmp/build_a2694fbe/src/pages/home/index.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/request',
    /* internal name: '/request' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/request/[id]' */
        /* no component */
        children: [
          {
            path: 'checkout',
            /* internal name: '/request/[id]/checkout' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'CheckoutPage',
                component: () => import('/tmp/build_a2694fbe/src/pages/request/[id]/checkout/index.vue'),
                /* no children */
              },
  _definePage_default_5
  )
            ],
          },
          {
            path: 'contractors',
            /* internal name: '/request/[id]/contractors' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'RequestContractorsPage',
                component: () => import('/tmp/build_a2694fbe/src/pages/request/[id]/contractors/index.vue'),
                /* no children */
              },
  _definePage_default_6
  ),
              {
                path: 'add',
                /* internal name: '/request/[id]/contractors/add' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'RequestContractorsAddPage',
                    component: () => import('/tmp/build_a2694fbe/src/pages/request/[id]/contractors/add/index.vue'),
                    /* no children */
                  },
  _definePage_default_7
  ),
  _mergeRouteRecord(
                  {
                    path: ':contractor_id',
                    name: 'RequestContractorsEditPage',
                    component: () => import('/tmp/build_a2694fbe/src/pages/request/[id]/contractors/add/[contractor_id].vue'),
                    /* no children */
                  },
  _definePage_default_8
  )
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'register',
        /* internal name: '/request/register' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/request/register/[id]' */
            /* no component */
            children: [
              {
                path: 'checkout',
                name: '/request/register/[id]/checkout',
                component: () => import('/tmp/build_a2694fbe/src/pages/request/register/[id]/checkout.vue'),
                /* no children */
              }
            ],
          },
          {
            path: ':id?',
            /* internal name: '/request/register/[[id]]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'RequestRegisterPage',
                component: () => import('/tmp/build_a2694fbe/src/pages/request/register/[[id]]/index.vue'),
                /* no children */
              },
  _definePage_default_9
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/user',
    /* internal name: '/user' */
    /* no component */
    children: [
      {
        path: ':uid',
        /* internal name: '/user/[uid]' */
        /* no component */
        children: [
          {
            path: ':token',
            /* internal name: '/user/[uid]/[token]' */
            /* no component */
            children: [
              {
                path: 'reset-password',
                /* internal name: '/user/[uid]/[token]/reset-password' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'RecoverPasswordPage',
                    component: () => import('/tmp/build_a2694fbe/src/pages/user/[uid]/[token]/reset-password/index.vue'),
                    /* no children */
                  },
  _definePage_default_10
  )
                ],
              }
            ],
          }
        ],
      }
    ],
  }
]
