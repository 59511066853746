<template>
  <requests-datatable/>
</template>

<style scoped lang="sass">

</style>

<script setup>
import RequestsDatatable from '@/components/RequestsDatatable.vue';
import { useAppStore } from '@/stores/app';
import { onMounted } from 'vue';
import types from '@/types';

const appStore = useAppStore();

definePage({
  name: 'Home',
  alias: ['/'],
  meta: {
    requiresAuth: true,
    isAuthPage: false,
  },
});

onMounted(async () => {
  await appStore[types.SETUP_APP]();
});
</script>
