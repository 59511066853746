/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { createRouter, createWebHashHistory } from 'vue-router/auto';
import { setupLayouts } from 'virtual:generated-layouts';
import { isLoggedIn } from '@/router/guards/auth.guard';
// import {createWebHashHistory} from "vue-router";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts,
  mode: 'hash',
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    isLoggedIn(to, from, next);
  } else if (to.meta.isAuthPage) {
    if (localStorage.token && localStorage.refresh) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
