import { defineStore } from 'pinia';
import types from '@/types';
import ContractorService from '@/services/ContractorService';

const useContractorService = new ContractorService();

const useContractorStore = defineStore('contractor', {
  state: () => ({
    contractor: null,
  }),
  actions: {
    async [types.GET_CONTRACTOR]() {
      await useContractorService.get_contractor_info().then((data) => {
        this.contractor = data;
      }).catch((error) => {
        console.error(error);
      });
      return this.contractor;
    },
  },
});

export default useContractorStore;
