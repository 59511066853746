import { defineStore } from 'pinia';
import types from '@/types';
import RequestService from '@/services/RequestService';

const useRequestService = new RequestService();

const useRequestStore = defineStore('requests', {
  state: () => ({
    requestHeadById: null,
    requestById: null,
    requests: [],
    requestTypes: [],
    requestStatuses: [],
    requestIdentificationTypes: [],
    requestValidities: [],
    requestNationalities: [],
    accessAreas: [],
  }),
  actions: {
    async [types.GET_REQUESTS]({ filters, id }) {
      const params = {
        filters, id,
      };

      if (id) {
        this.requestById = await useRequestService.getRequests(params);
      } else {
        this.requests = await useRequestService.getRequests(params);
      }
    },
    async [types.GET_REQUEST_TYPES](onlyRegister) {
      this.requestTypes = await useRequestService.getRequestTypes(onlyRegister);
    },
    async [types.GET_REQUEST_STATUSES]() {
      this.requestStatuses = await useRequestService.getRequestStatuses();
    },
    async [types.GET_REQUEST_IDENTIFICATION_TYPES](onlyIdTypes) {
      this.requestIdentificationTypes = await useRequestService.getRequestIdentificationTypes(onlyIdTypes);
    },
    async [types.CREATE_REQUEST](form) {
      const files = form.filesBase64;
      form.filesBase64 = [];
      Object.keys(files).map((key) => {
        form.filesBase64.push(files[key]);
      });

      const request = await useRequestService.createRequest(form);
      await this[types.SET_DOCUMENT_REQUEST](request, [form.filesBase64]);
      return request;
    },
    async [types.CREATE_REQUEST_HEAD](form) {
      return useRequestService.createRequestHead(form);
    },
    async [types.GET_REQUEST_HEAD]({ filters, id }) {
      if (id) {
        this.requestHeadById = await useRequestService.getRequestHead({ filters, id });
      } else {
        this.requests = await useRequestService.getRequestHead({ filters, id });
      }
    },
    async [types.GET_REQUESTS_FROM_HEAD](id) {
      this.requestById = await useRequestService.getRequestsFromHead(id);
    },
    async [types.SET_DOCUMENT_REQUEST](request, files) {
      return useRequestService.setDocumentRequest(request, files);
    },
    async [types.SET_DOCUMENT_CARNET_REQUEST](request, files) {
      return useRequestService.setDocumentCarnetRequest(request, files);
    },
    async [types.GET_REQUEST_DOCUMENTS](id) {
      return useRequestService.getDocumentRequest(id);
    },
    async [types.GET_REQUEST_CARNET_DOCUMENTS](id) {
      return useRequestService.getDocumentCarnetRequest(id);
    },
    async [types.UPDATE_REQUEST](id, form, editContractor = false) {
      const files = form.filesBase64;
      form.filesBase64 = [];
      Object.keys(files).map((key) => {
        form.filesBase64.push(files[key]);
      });

      const request = await useRequestService.updateRequest(id, form, editContractor);
      await this[types.SET_DOCUMENT_REQUEST](request, [form.filesBase64]);
      return request;
    },
    async [types.REQUEST_ADD_CONTRACTOR](requestHead, form) {
      const files = form.filesBase64;
      form.filesBase64 = [];
      Object.keys(files).map((key) => {
        form.filesBase64.push(files[key]);
      });

      const request = await useRequestService.createRequestEmployee(requestHead, form);
      await this[types.SET_DOCUMENT_CARNET_REQUEST](request, [form.filesBase64]);
      return request;
    },
    async [types.REQUEST_UPDATE_CONTRACTOR](requestHead, form, editContractor = false) {
      const files = form.filesBase64;
      form.filesBase64 = [];
      Object.keys(files).map((key) => {
        form.filesBase64.push(files[key]);
      });

      const request = await useRequestService.updateRequestEmployee(requestHead, form, editContractor);
      await this[types.SET_DOCUMENT_CARNET_REQUEST](request, [form.filesBase64]);
      return request;
    },
    async [types.REQUEST_VALIDITIES](id) {
      this.requestValidities = await useRequestService.getRequestValidities(id);
    },
    async [types.GET_NATIONALITIES]() {
      this.requestNationalities = await useRequestService.getNationalities();
    },
    async [types.CANCEL_REQUEST](requestId) {
      return useRequestService.cancelRequest(requestId);
    },
    async [types.GET_CARNETS_TEMPORARY](headId, requestId = 'all') {
      return useRequestService.getCarnetTemporary(headId, requestId);
    },
    async [types.GET_DATA_BY_IDENTIFICATION](idNumber) {
      return useRequestService.getDataByIdentification(idNumber);
    },
    async [types.GET_INVOICE](sapCustomer, invoiceId, merchantNumber) {
      return useRequestService.getInvoice(sapCustomer, invoiceId, merchantNumber);
    },
    async [types.GET_ACCESS_AREAS]() {
      this.accessAreas = await useRequestService.getAccessAreas();
    },
  },
});

export default useRequestStore;
