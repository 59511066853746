<style scoped lang="sass">

</style>

<template>
  <forgot-password-form/>
</template>

<script setup>

import ForgotPasswordForm from '@/components/forms/auth/ForgotPasswordForm.vue';

definePage({
  name: 'ForgotPasswordPage',
  alias: 'auth/forgot-password',
  meta: {
    requiresAuth: false,
    isAuthPage: true,
  },
});
</script>
