import axios from 'axios';

class BaseService {
  constructor({ resource }) {
    this.resource = `${resource}`;
    this.http = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
    });

    this.http.interceptors.request.use((config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Application = import.meta.env.VITE_BIFROST;
      }
      return config;
    });
  }

  getUrl(id) {
    return `${this.resource}${id}/`;
  }

  async create(data) {
    return this.http.post(this.resource, data);
  }

  async getList(params) {
    return this.http.get(this.resource, { params });
  }

  async get(id) {
    return this.http.get(this.getUrl(id));
  }

  async update(id, data) {
    return this.http.put(this.getUrl(id), data);
  }
}

export default BaseService;
